<template>
    <div>
        <div class="base-box">
            <el-row>
                <el-col class="col-title tr" :span="6">经营地址</el-col>
                <el-col class="col-val" :span="16">{{baseInfo.provinceCode | provinceCityFM}}-{{baseInfo.cityCode | provinceCityFM(baseInfo.provinceCode)}}</el-col>
            </el-row>
            <el-row>
                <el-col class="col-title tr" :span="6"></el-col>
                <el-col class="col-val" :span="16">{{baseInfo.businessAddress}}</el-col>
            </el-row>
            <el-row>
                <el-col class="col-title tr" :span="6">法人代表姓名</el-col>
                <el-col class="col-val" :span="16">{{baseInfo.legalName}}</el-col>
            </el-row>
            <el-row>
                <el-col class="col-title tr" :span="6">法人代表身份证</el-col>
                <el-col class="col-val" :span="16">{{baseInfo.legalIdCardNo}}</el-col>
            </el-row>
            <el-row>
                <el-col class="col-title tr" :span="6">身份证有效期</el-col>
                <el-col class="col-val" :span="16">{{baseInfo.legalIdCardValidity == '0' ? '长期': baseInfo.legalIdCardValidity}}</el-col>
            </el-row>
            <el-row>
                <el-col class="col-title tr" :span="6">运营人员</el-col>
                <el-col class="col-val" :span="16">{{baseInfo.operaterUserName}}</el-col>
            </el-row>
            <el-row>
                <el-col class="col-title tr" :span="6">联系人手机号</el-col>
                <el-col class="col-val" :span="16">{{baseInfo.contactsMobilePhone}}</el-col>
            </el-row>
            <el-row>
                <el-col class="col-title tr" :span="6">账户类型</el-col>
                <el-col class="col-val" :span="16">{{setterInfo.accountType | accountTypeFM}}</el-col>
            </el-row>
            <el-row>
                <el-col class="col-title tr" :span="6">开户名</el-col>
                <el-col class="col-val" :span="16">{{businessInfo.companyName}}</el-col>
            </el-row>
            <el-row>
                <el-col class="col-title tr" :span="6">结算账户号码</el-col>
                <el-col class="col-val" :span="16">{{setterInfo.accountNo}}</el-col>
            </el-row>
            <el-row>
                <el-col class="col-title tr" :span="6">开户行</el-col>
                <el-col class="col-val" :span="16">{{setterInfo.bankName}}</el-col>
            </el-row>

        </div>
    </div>
</template>
<script>
import { AgentApi,UserApi } from '@/api'
export default {
    data(){
        return{
            activeName: 'first',
            baseInfo: '',
            businessInfo:'',
            setterInfo:'',
            isUpdate: false,
            cityList: [],
            provinceList: [],
            operaterUserList: [],
            isLoading: false,
            agentNo:'',
            rules: {
                provinceCode: [{ required: true, message: '请选择省份', trigger: 'change' }],
                cityCode: [{ required: true, message: '请选择城市', trigger: 'change' } ],
                businessAddress: [{ required: true, message: '请输入经营地址', trigger: 'blur' }],
                operaterUserNo: [ {required: true, message: '请选择运营人员', trigger: 'change' } ],
                contactsMobilePhone: [ {  required: true, message: '请输入联系人手机号', trigger: 'blur' },{pattern: /^1[3|4|5|6|7|8|9][0-9]{9}$/, message: '请输入正确的手机号码'}],
            },
            detailData:{}
        }
    },
    created(){
        
        this.getDetail()
        UserApi.user.list(1,2000)
            .then(res=>{
                if(res.success){
                    this.operaterUserList = res.data
                }
            })
    },
    methods:{
        getDetail(){
            this.agentNo = this.$route.query.agentNo
            AgentApi.agentDetail(this.agentNo)
            .then(res=>{
                if(res.success){
                    this.baseInfo = res.data.baseInfo
                    this.businessInfo = res.data.businessInfo
                    this.setterInfo = res.data.setterInfo
                }
            })
        },
       upDateClick(formName){
            if(this.isUpdate){
               this.isLoading = true
                let params = {
                    contactsMobilePhone: this.baseInfo.contactsMobilePhone,
                    operaterUserNo: this.baseInfo.operaterUserNo,
                    provinceCode: this.businessInfo.provinceCode,
                    cityCode: this.businessInfo.cityCode,
                    businessAddress: this.businessInfo.businessAddress
                }
                AgentApi.updateAgentBaseInfo(this.agentNo,params)
                    .then(res=>{
                        this.isLoading = false
                        this.isUpdate = res.success ? false : true
                        // this.$message('修改成功')
                        this.getDetail()
                    })
                
            } else{
                this.isUpdate = true
            }
        }
    }
}
</script>